
/* general */

body {
  margin: 0;
  background-color: #f0f0f0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, h1, h2, h3, h4, h5 {
  color: #222 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.H3 {
  color: #555 !important;
}

/* header */

.Navigation-bar a {
  color: #def;
}

.Navigation-bar a:hover{
  color: #fff;
}

.Navigation-bar .dropdown button, .Navigation-bar .dropdown div {
  color: #def;
  background-color: #000;
  border-width: 0;
}

.Navigation-bar .dropdown a:hover div {
  background-color: #fff;
}

.Navigation-bar .dropdown-menu {
  min-width: 40px;
}

.Flag-img {
  height: 1em;
}

.Banner {
  display: flex;
  flex-direction: column;
}

.Menu {
  display: flex;
  flex-direction: row;
  background-color: black;
}

/* page */

.Page {
  margin-bottom: 2em;
}

.Page-section {
  min-width: 20em;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
}

.Page-section-header {
  text-align: center;
}

.Page-section-description {
  text-align: center;
  color: #777;
}

.Subsections-page-part {
  background: black;
}

.Subsections-page-part .Subsections-title {
  color: #def !important;
}

.Subsections-page-part .btn {
  color: #222;
  background: #def;
}

.Subsections-page-part .btn:hover {
  color: black;
  background: white;
}

.Internal-link {
  border-top-color: var(--bs-link-color);
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-color: var(--bs-link-color);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-decoration: none;
}

.Quote {
  font-size: var(--bs-body-font-size) !important;
  background-color: #ff02;
}

/* faded background */

.Fade-line {
  width: 100%;
  min-height: 20px;
  max-height: 50px;
  vertical-align: top;
}

.Inverted {
  transform: scale(1,-1);
  -webkit-transform: scale(1,-1);
  -ms-transform: scale(1,-1);
  vertical-align: bottom;
}

